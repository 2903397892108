import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,4,2];

export const dictionary = {
		"/(homepage)": [17,[4]],
		"/(homepage)/about": [18,[4]],
		"/(app)/dashboard": [~5,[2]],
		"/(app)/expenses": [~6,[2]],
		"/(homepage)/faq": [19,[4]],
		"/(auth)/logging-in": [12],
		"/(homepage)/login": [20,[4]],
		"/logout": [23],
		"/(homepage)/privacy": [21,[4]],
		"/(auth)/register": [13,[3]],
		"/(auth)/register/add-categories": [14,[3]],
		"/(auth)/register/add-filters": [~15,[3]],
		"/(auth)/register/setup-email": [16,[3]],
		"/(app)/review": [~7,[2]],
		"/(app)/settings": [8,[2]],
		"/(app)/settings/categories": [~9,[2]],
		"/(app)/settings/sender-email": [~10,[2]],
		"/(app)/settings/user": [~11,[2]],
		"/(homepage)/tnc": [22,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';